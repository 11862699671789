import LoggerUtils from './logger.utils'
import LoggerRoutes from './logger.routes'

export default ({ config, authentication }) => {
  const loggerUtils = LoggerUtils({ authentication })
  const { error, debug, info } = LoggerRoutes({ config, loggerUtils })

  return {
    error,
    debug,
    info,
  }
}
