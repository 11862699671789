export default function ({ loader, DOMAINS }) {
  const { FeatureToggle: { name, aliasImport } } = DOMAINS

  const dependencies = () => loader.import(aliasImport)

  return {
    name,
    dependencies,
  }
}
