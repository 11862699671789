const domains = {
  SelfService: {
    name: 'selfservice',
    aliasImport: '@platform/selfservice',
    path: '/home',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  CustomerPanel: {
    name: 'customer-area',
    aliasImport: '@platform/customer-area',
    path: '/customer-area',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  Components: {
    name: 'components',
    aliasImport: '@platform/components',
    path: '',
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelMessenger: {
    name: 'channel-messenger',
    aliasImport: '@platform/channel-messenger',
    path: '/channels/messenger',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelSms: {
    name: 'channel-sms',
    aliasImport: '@platform/channel-sms',
    path: '/channels/sms',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelVoice: {
    name: 'channel-voice',
    aliasImport: '@platform/channel-voice',
    path: '/channels/voice',
    unloadApp: true,
    auth: true,
  },
  ChannelWhatsApp: {
    name: 'channel-whatsapp',
    aliasImport: '@platform/channel-whatsapp',
    path: '/channels/whatsapp',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelWebchat: {
    name: 'channel-webchat',
    aliasImport: '@platform/channel-webchat',
    path: '/channels/webchat',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelRcs: {
    name: 'channel-rcs',
    aliasImport: '@platform/channel-rcs',
    path: '/channels/rcs',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  Flow: {
    name: 'flow',
    aliasImport: '@platform/flows',
    path: '/bots',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  Organizations: {
    name: 'organizations',
    aliasImport: '@platform/organizations',
    path: '/organizations',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  ChannelInstagram: {
    name: 'channel-instagram',
    aliasImport: '@platform/channel-instagram',
    path: '/channels/instagram',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  PlatformReport: {
    name: 'platform-report',
    aliasImport: '@platform/platform-report',
    path: '/report',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  homeV2: {
    name: 'home-v2',
    aliasImport: '@platform/home-v2',
    path: '/welcome',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  navbarV2: {
    name: 'navbar-v2',
    aliasImport: '@platform/navbar-v2',
    path: '',
    auth: true,
    isLayout: true,
  },
  channelV2: {
    name: 'channel-v2',
    aliasImport: '@platform/channel-v2',
    path: '/channels/email',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
  Templates: {
    name: 'templates',
    aliasImport: '@platform/templates',
    path: '/templates',
    unloadApp: true,
    auth: true,
    hideLayout: false,
    isLayout: false,
  },
}

const attractionDomains = {
  CampaignDispatches: {
    name: 'campaign-dispatches',
    aliasImport: '@platform/campaign-dispatches',
    path: '/attraction',
    auth: true,
    unloadApp: true,
  },
  CampaignSidebar: {
    name: 'campaign-sidebar',
    aliasImport: '@platform/campaign-sidebar',
    path: '/attraction',
    auth: true,
    unloadApp: true,
  },
  CampaignContacts: {
    name: 'contacts',
    aliasImport: '@platform/contacts',
    path: '/attraction/contacts',
    auth: true,
    unloadApp: true,
  },
  CampaignAutomation: {
    name: 'campaign-automation',
    aliasImport: '@platform/campaign-automation',
    path: '/attraction/automation',
    auth: true,
    unloadApp: true,
  },
  CampaignBuilder: {
    name: 'builder',
    aliasImport: '@platform/builder',
    path: '/attraction/builder',
    auth: true,
    unloadApp: true,
  },
  PlatformBroadcast: {
    name: 'broadcast',
    aliasImport: '@platform/broadcast',
    path: '/attraction/campaign',
    auth: true,
    unloadApp: true,
  },
  BroadcastSocial: {
    name: 'broadcast-social',
    aliasImport: '@platform/broadcast-social',
    path: '/attraction/social',
    auth: true,
    unloadApp: true,
  },
  BroadcastMonitoring: {
    name: 'broadcast-monitoring',
    aliasImport: '@platform/broadcast-monitoring',
    path: '/attraction/monitoring/',
    auth: true,
    unloadApp: true,
  },
}

export default { ...domains, ...attractionDomains }
