export default ({ filterRedirect }) => {
  const redirectUrl = ({ url }) => {
    const pathName = url.pathname === '/' ? '/welcome' : url.pathname

    return filterRedirect.build({ pathName, url })
  }

  const build = ({ url }) => {
    const params = url.searchParams
    const noParams = url.pathname === '/'
    const sanitizedPath = noParams ? '/welcome' : url.pathname

    const searchParams = Array.from(params.keys())

    if (searchParams.includes('auth0Redirect')) {
      params.delete('auth0Redirect', 'code', 'state')
    }

    if (searchParams.length) {
      return filterRedirect.decode({ url })
    }

    return sanitizedPath
  }

  return { build, redirectUrl }
}
