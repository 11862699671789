export default function ({ SystemJS }) {
  return {
    import: (alias) => {
      try {
        return SystemJS.import(alias)
      } catch (ex) {
        throw new Error(JSON.stringify({
          message: `[${process.env.APP_NAME}] > Error in Loader`,
          error: ex.message,
        }))
      }
    },
  }
}
