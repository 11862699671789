export default function (dependencies) {
  return {
    imports: dependencies.reduce((acc, curr) => {
      const { name, url, context } = curr
      const importAlias = name.startsWith('zsuite') ? `@zsuite/${name.replace('zsuite-', '')}` : `@platform/${name}`
      if (context === 'APP') return { ...acc, [importAlias]: url }
      if (context === 'SUITE') return { ...acc, [importAlias]: url }
      if (context === 'COMMON') return { ...acc, [importAlias]: url }
      return { ...acc, [importAlias]: url }
    }, {}),
  }
}
