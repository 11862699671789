import DomainLoader from './domain-loader'

export default function ({
  singleSpa, loader, logService, currentContext,
}) {
  const { singleSpaAppsConfig } = new DomainLoader({
    singleSpa, loader, logService, currentContext,
  })

  return {
    apps: singleSpaAppsConfig,
  }
}
