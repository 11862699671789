import axios from 'axios'

export default function ({ authentication }) {
  async function updateChameleonProperties(appContext, fullContextPayload) {
    const { getSession } = await authentication.loadAuth0()

    const {
      user, organization, profile, credential: { jwtData: { token } },
    } = await getSession()

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    }

    const { data } = await axios.post(`${process.env.CUSTOMER_DATA_SERVICE}/v1/customer/encrypt-data`, {}, config)

    // Sending data to chameleon

    console.log('CHL CXT', appContext || window.maestroContextType || 'APP')

    chmln.identify(user.id, {
      uid_hash: data,
      name: user.name,
      email: user.email,
      logins: user.email,
      created: user.createdAt,
      updatedAt: user.updatedAt,
      lastLogin: user.lastLogin,
      language_str: user.language,
      lang: navigator.language || undefined,
      role: profile.name,
      is_suite: appContext || window.maestroContextType === 'SUITE' || false,
      company: {
        uid: organization.id,
        name: organization.name,
        plan: organization.zenviaPlan,
        shortCode: organization.shortCode,
        created: organization.createdAt,
        updatedAt: organization.updatedAt,
      },
      customer_type: fullContextPayload.customer_type || '',
      is_migrated_from_another_plan: fullContextPayload.is_migrated_from_another_plan || false,
      is_migrated_from_conversion: fullContextPayload.is_migrated_from_conversion || false,
      suitePlanChannel: fullContextPayload.suitePlanChannel || '',
      suitePlanSoftware: fullContextPayload.suitePlanSoftware || '',
      products: fullContextPayload.products ? fullContextPayload.products.join(',') : '',
      product: user.system,
    }, config)
  }

  return {
    updateChameleonProperties,
  }
}
