import * as singleSpa from 'single-spa'

/* global SystemJS */
import axios from 'axios'
import Domain from './Domain'
import Infra from './Infra'
import Services from './Services'
import * as constants from './Constants'
import Config from './Constants/Paths'
import BLOCKEDSTATUS from './Constants/Domains/blockedStatus'
import CANCELEDSTATUS from './Constants/Domains/canceledStatus'

const config = new Config({ constants })
const {
  appRegistration,
  loader,
  registerDependencies,
  urlBuilder,
  redirectGuard,
  checkAppContext,
} = new Infra({ singleSpa, SystemJS })
const {
  authentication, logService, chameleon, eventBus, customerData, fullstory,
} = new Services({ loader, constants, config })

window.navigateToUrl = singleSpa.navigateToUrl

const storeLogTrace = async () => {
  try {
    const { APP: { logTraceKey } } = constants
    localStorage.setItem(logTraceKey, JSON.stringify({ storeTraceId: '', storedSpanId: '' }))

    return logService.info({ message: 'Initialized session' })
  } catch (ex) {
    console.error(ex.message)
  }
}

async function updateUserDataAnalytics(context, fullContextPayload) {
  try {
    const resolveChameleon = chameleon.updateChameleonProperties(context, fullContextPayload)
    const resolveFullstory = fullstory.initService(context, fullContextPayload)
    const [chameleonResponse, fullStoryResponse] = await Promise.allSettled([resolveChameleon, resolveFullstory])
    if (chameleonResponse.status === 'rejected') {
      console.error('error on load CH')
    }
    if (fullStoryResponse.status === 'rejected') {
      console.error('error on load FS')
    }
  } catch (ex) {
    console.error(ex.message)
  }
}

async function setAppContext() {
  const customerSuiteContext = await customerData.getContext()
  // THIS CHECKS IF IS SUITE OR APP
  const currentContext = await checkAppContext(customerSuiteContext)
  return { currentContext, customerSuiteContext }
}

const checkLoggedUserAndRegisterApps = async () => {
  const {
    loadClient,
    getSession,
    login,
    prepareAndCheckAuth,
    sessionTimerStart,
  } = await authentication.loadAuth0()
  const client = await loadClient()
  await prepareAndCheckAuth()
  sessionTimerStart()

  storeLogTrace(logService)

  const currentUrl = new URL(window.location)

  const { isUserAuthenticated, mustLogin } = await authentication.isUserAuthenticated({ client, getSession, logService })

  if (!isUserAuthenticated && mustLogin) {
    const auth0Redirect = urlBuilder.redirectUrl({ url: currentUrl })

    return login({ auth0Redirect })
  }

  const { currentContext, customerSuiteContext } = await setAppContext()
  const domains = new Domain({
    singleSpa, loader, logService, currentContext,
  })
  const { planStatus } = customerSuiteContext

  appRegistration(domains, isUserAuthenticated)

  if (isUserAuthenticated) {
    const path = urlBuilder.build({ url: currentUrl })
    const appName = path.split('/').pop()
    const { onboardingCompleted } = customerSuiteContext

    if (planStatus === 'BLOCKED') {
      if (!BLOCKEDSTATUS.ALLOWEDROUTES.includes(path)) {
        if (path === '/' || path === '/welcome') {
          singleSpa.navigateToUrl('/customer-area/financial')
        } else {
          singleSpa.navigateToUrl('/error?errorType=blockedPlan')
        }
        return
      }

      singleSpa.registerApplication({
        name: appName,
        activeWhen: BLOCKEDSTATUS.ALLOWEDROUTES,
      })
    } else if (planStatus === 'CANCELED') {
      if (path === '/' || path === '/welcome') {
        singleSpa.navigateToUrl('/welcome')
        return
      }

      if (CANCELEDSTATUS.BLOCKEDROUTES.includes(path)) {
        singleSpa.navigateToUrl('/error?errorType=canceledPlan')
        return
      }

      singleSpa.registerApplication({
        name: appName,
        activeWhen: (location) => !CANCELEDSTATUS.BLOCKEDROUTES.includes(location.pathname),
      })
    }

    if (!onboardingCompleted && currentContext === 'SUITE' && !customerSuiteContext.is_migrated_from_another_plan) {
      singleSpa.navigateToUrl('/onboarding')
    } else {
      singleSpa.navigateToUrl(path)
    }

    return updateUserDataAnalytics(currentContext, customerSuiteContext)
  }
  singleSpa.navigateToUrl('/error?errorType=403') // User doesn't have access to Platform
}

const refreshMfesWhenOrgChange = async () => {
  singleSpa.getMountedApps()
    .filter((appName) => !['navbar-v2', 'components', 'zsuite-navbar'].includes(appName))
    .forEach((appName) => singleSpa.unloadApplication(appName))
  const oldContext = window.maestroContextType
  const { currentContext: newContext } = await setAppContext()
  console.log(`old: ${oldContext}, new: ${newContext}`)
  if (oldContext === newContext) {
    singleSpa.triggerAppChange()
  } else {
    window.location.href = '/'
  }
}

const handlePushSubscriptionNotification = () => {
  navigator.serviceWorker.register('service-worker.js')
    .then(async (serviceWorker) => {
      const subscription = await serviceWorker.pushManager.getSubscription()
      if (!subscription) {
        try {
          Notification.requestPermission().then(async (permission) => {
            if (permission !== 'granted') {
              return
            }
            const { getSession } = await authentication.loadAuth0()
            const { credential: { jwtData: { token } } } = await getSession()
            const configHeader = {
              headers: { Authorization: `Bearer ${token}` },
            }
            const { data } = await axios.get(`${process.env.PLATFORM_NOTIFICATION_API}/v2/push/public_key`, configHeader)
            await serviceWorker.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: data.publicKey,
            }).then(async (response) => {
              const payload = {
                subscription: response,
                language: navigator.language,
              }
              await axios.post(`${process.env.PLATFORM_NOTIFICATION_API}/v2/push/register`, payload, configHeader)
            }).catch((err) => {
              throw new Error(JSON.stringify({
                message: `[${process.env.APP_NAME}] > Error in pushManager subscribe notifications`,
                error: err.message,
              }))
            })
          })
        } catch (error) {
          throw new Error(JSON.stringify({
            message: `[${process.env.APP_NAME}] > Error in init push notifications`,
            error: error.message,
          }))
        }
      }
    })
    .catch((error) => {
      console.error('Erro ao registrar o Service Worker:', error)
    })
}
const listenerEventBus = async () => {
  const { listen } = await eventBus.loadEventBus()
  listen(
    {
      name: 'refreshMfes',
      callback: () => { refreshMfesWhenOrgChange() },
    },
    {
      name: 'allowNotification',
      callback: () => { handlePushSubscriptionNotification() },
    },
  )
}

window.onload = async () => {
  const { APP: { environment, name } } = constants
  try {
    await redirectGuard()
    registerDependencies({ environment, config })
    checkLoggedUserAndRegisterApps()
    listenerEventBus()
    if (window.location.pathname.includes('onboarding')) document.querySelector('#main-navbar').remove()
  } catch (ex) {
    throw new Error(JSON.stringify({
      message: `[${name}] > ${ex}`,
      error: ex.message,
    }))
  }
}

window.addEventListener('single-spa:before-routing-event', (evt) => {
  const { totalAppChanges, appsByNewStatus, newUrl } = evt.detail

  const hasAppChanging = !!totalAppChanges
  if (hasAppChanging) {
    const urlDist = new URL(newUrl)

    const mountedApps = singleSpa.getMountedApps()
      .concat(appsByNewStatus.MOUNTED)
      .filter((el) => !appsByNewStatus.NOT_MOUNTED.includes(el))
      .filter((el) => !['navbar-v2', 'components'].includes(el))
    const hasMountedApps = mountedApps.length > 0

    const isRootPath = urlDist.pathname === '/'
    const isSSORedirect = urlDist.searchParams.has('auth0Redirect')

    if (!(isRootPath || isSSORedirect || hasMountedApps)) { // Route not found
      console.log('willRedirect', { isRootPath, isSSORedirect, hasMountedApps })
      singleSpa.navigateToUrl('/error?errorType=404')
    }
  }
})

window.addEventListener('allow-notification', () => {
  handlePushSubscriptionNotification()
})

setTimeout(() => {
  handlePushSubscriptionNotification()
}, 5000)
