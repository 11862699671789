export default {
  Authentication: {
    name: 'authentication',
    aliasImport: '@platform/authentication',
    isService: true,
  },
  FeatureToggle: {
    name: 'feature-toggle',
    aliasImport: '@platform/feature-toggle',
    isService: true,
  },
  EventBus: {
    name: 'eventbus',
    aliasImport: '@platform/eventbus',
    isService: true,
  },
  feedbackService: {
    name: 'feedback-service',
    aliasImport: '@platform/feedback-service',
    isService: true,
  },
  customerDataService: {
    name: 'customer-data-service',
    aliasImport: '@platform/customer-data-service',
    isService: true,
  },
  PlatformErrorsPage: {
    name: 'platform-errors-page',
    aliasImport: '@platform/platform-errors-page',
    path: '/error',
    unloadApp: true,
    auth: false,
    hideLayout: false,
    isLayout: false,
  },
}
