export default ({ config, loggerUtils }) => {
  const { LOGS_URL } = config
  const { buildRequestOptions, updateTraceOnStorage } = loggerUtils

  const error = async ({ message, keys }) => {
    try {
      const options = buildRequestOptions({ message, keys, logType: 'ERROR' })

      if (!(options.headers || options.headers.Authorization)) return

      const response = await fetch(`${LOGS_URL}/logs`, { ...options })

      const { traceId, spanId } = await response.json()
      return updateTraceOnStorage({ traceId, spanId })
    } catch (ex) {
      throw Error(ex)
    }
  }

  const debug = async ({ message, keys }) => {
    try {
      const options = buildRequestOptions({ message, keys, logType: 'DEBUG' })

      if (!(options.headers || options.headers.Authorization)) return

      const response = await fetch(`${LOGS_URL}/logs`, { ...options })

      const { traceId, spanId } = await response.json()
      return updateTraceOnStorage({ traceId, spanId })
    } catch (ex) {
      throw Error(ex)
    }
  }

  const info = async ({ message, keys }) => {
    try {
      const options = buildRequestOptions({ message, keys, logType: 'INFO' })

      if (!(options.headers || options.headers.Authorization)) return

      const response = await fetch(`${LOGS_URL}/logs`, { ...options })

      const { traceId, spanId } = await response.json()
      return updateTraceOnStorage({ traceId, spanId })
    } catch (ex) {
      throw Error(ex)
    }
  }

  return {
    error,
    debug,
    info,
  }
}
