import AppRegistration from './registration/apps-registration'
import ValidateDomain from './validateDomain'
import Loader from './loader'
import registerDependencies from './dependencies'
import urlBuilder from './urlBuilder'
import filterRedirect from './filterRedirect'
import RedirectGuard from './redirectGuard'
import AppContext from './appContext'

export default function ({ singleSpa, SystemJS }) {
  const { isValidName } = ValidateDomain
  const loader = new Loader({ SystemJS })
  const appRegistration = new AppRegistration({ singleSpa, isValidName })
  const redirectGuard = RedirectGuard({ navigateToUrl: singleSpa.navigateToUrl })
  const checkAppContext = AppContext()

  return {
    appRegistration,
    loader,
    registerDependencies,
    urlBuilder: urlBuilder({ filterRedirect }),
    redirectGuard,
    checkAppContext,
  }
}
