// THIS METHOD CHECKS USER CONTEXT (PLAN) TO CHECK IF SHOULD USE APP OR SUITE MODE

export default () => (context) => {
  let CONTEXT = 'APP'
  if (context && context.isSuite) CONTEXT = 'SUITE'
  const FORCED_CONTEXT = localStorage.getItem('appContext')
  if (FORCED_CONTEXT && FORCED_CONTEXT !== '') CONTEXT = FORCED_CONTEXT
  if (CONTEXT !== 'SUITE' && CONTEXT !== 'APP') CONTEXT = 'APP'
  window.maestroContextType = CONTEXT
  return CONTEXT
}
