export default {
  BLOCKEDROUTES: [
    '/contacts',
    '/contacts/segments',
    '/broadcast/homepage',
    '/customer-service/inbox/me?sidebar=true',
    '/customer-service/integrations',
    '/customer-service/settings',
    '/chatbot/list',
    '/chatbot/document',
    '/chatbot/contacts',
  ],
}
