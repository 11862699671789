import { APP } from '../../Constants'

export default ({ authentication }) => {
  const { name, logTraceKey } = APP
  const userId = localStorage.getItem(process.env.ZENVIA_USER_ID)

  const logsTracingHeaders = ({ traceId, spanId }) => {
    const hasTraceInLocalStorage = traceId && spanId

    return (hasTraceInLocalStorage)
      ? { 'X-TraceId': traceId, 'X-SpanId': spanId }
      : {}
  }

  const updateTraceOnStorage = ({ traceId, spanId }) => {
    localStorage.setItem(logTraceKey, JSON.stringify({
      storedTraceId: traceId,
      storedSpanId: spanId,
    }))
  }

  const buildRequestOptions = ({ message, keys, logType }) => {
    const parsedLocalStorage = JSON.parse(localStorage.getItem(logTraceKey))
    const { storedTraceId, storedSpanId } = parsedLocalStorage

    const token = authentication.getJWTToken()
    const authorization = token ? `Bearer ${token}` : undefined

    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...logsTracingHeaders({ traceId: storedTraceId, spanId: storedSpanId }),
        Authorization: authorization,
      },
      body: JSON.stringify({
        application: name,
        message,
        keys: {
          user: userId,
          device: navigator.userAgent.toLowerCase(),
          ...keys,
        },
        logType,
      }),
    }
  }

  return {
    updateTraceOnStorage,
    buildRequestOptions,
  }
}
