export default function ({ loader, DOMAINS }) {
  const { Authentication: { aliasImport } } = DOMAINS

  // eslint-disable-next-line no-return-await
  const loadAuth0 = async () => await loader.import(aliasImport)

  const getUserID = () => localStorage.getItem(process.env.ZENVIA_USER_ID) || undefined

  const setUserID = (id) => {
    if (!id) return
    localStorage.setItem(process.env.ZENVIA_USER_ID, id)
  }

  const getJWTToken = () => localStorage.getItem(process.env.ZENVIA_JWT_TOKEN) || undefined

  const setTokenJWT = (token) => {
    if (!token) return
    localStorage.setItem(process.env.ZENVIA_JWT_TOKEN, token)
  }

  const authorizeSession = async (client, getSession) => {
    const { authorize } = await loadAuth0()
    const token = await client.getTokenSilently()
    await authorize({ token })

    return getSession()
  }

  const getSessionOrAuthorize = async ({ client, getSession, logService }) => {
    let session

    try {
      session = await getSession()
    } catch (error) {
      logService.debug({
        message: 'Error while trying to authenticate user',
        keys: { error: error.message },
      })

      session = await authorizeSession(client, getSession)
    }
    return session
  }

  const isUserAuthenticated = async ({ client, getSession, logService }) => {
    const result = {
      isUserAuthenticated: false,
      mustLogin: true,
    }
    try {
      const isAuthenticated = await client.isAuthenticated()
      if (isAuthenticated) {
        const session = await getSessionOrAuthorize({ client, getSession, logService })
        setUserID(session.user.id)
        setTokenJWT(session.credential.jwtData.token)
        logService.debug({
          message: 'user is authenticated',
          keys: { user: session.user.name },
        })
        result.isUserAuthenticated = !!session
      }
      return result
    } catch (ex) {
      logService.debug({
        message: 'user is not authenticated',
        keys: { error: ex.message },
      })
      result.mustLogin = false
      return result
    }
  }

  return {
    loadAuth0,
    isUserAuthenticated,
    getUserID,
    getJWTToken,
    setTokenJWT,
  }
}
