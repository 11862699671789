import { init, FullStory } from '@fullstory/browser'

const getOrg = (appContext) => {
  const orgsId = {
    SUITE: 'o-1VC44R-na1',
    APP: 'JHPMX',
  }

  console.log('FS CXT', appContext || window.maestroContextType || 'APP')
  const orgId = orgsId[appContext || window.maestroContextType || 'APP']

  return orgId
}

const identify = (user, organization, profile, fullContextPayload) => {
  try {
    const properties = {
      displayName: user.name,
      email: user.email,
      role: profile.name,
      meta: {
        createdAt_date: user.createdAt,
        lastLogin_date: user.lastLogin,
        updatedAt_date: user.updatedAt,
        language_str: user.language,
        suiteContext: fullContextPayload,
      },
      organization: {
        id_str: organization.id,
        name_str: organization.name,
        plan: organization.zenviaPlan,
        shortCode_str: organization.shortCode,
        createdAt_date: organization.createdAt,
        updatedAt_date: organization.updatedAt,
      },
      product: user.system,
    }
    FullStory('setIdentity', {
      uid: user.id,
      properties,
    })
  } catch (ex) {
    if (ex instanceof Error) {
      console.error(ex.message)
    }
  }
}

export default function ({ authentication, constants }) {
  async function initService(appContext, fullContextPayload) {
    const { APP: { environment } } = constants
    const { getSession } = await authentication.loadAuth0()

    const {
      organization,
      user,
      profile,
    } = await getSession()

    // const isInternal = user.email.includes('zenvia')
    const devMode = environment !== 'production'

    init({ orgId: getOrg(appContext), devMode })
    identify(user, organization, profile, fullContextPayload)
    // if (isInternal) FS('shutdown')
  }

  return {
    initService,
  }
}
