export default function ({ loader, DOMAINS }) {
  const { customerDataService: { aliasImport } } = DOMAINS

  // eslint-disable-next-line no-return-await
  const loadCustomerData = async () => await loader.import(aliasImport)

  const getContext = async () => {
    try {
      const { getContext: getContextFromCustomerPersonalData } = await loadCustomerData()
      const context = await getContextFromCustomerPersonalData()
      return context
    } catch (error) {
      return { isSuite: false }
    }
  }

  return {
    getContext,
  }
}
