import config from './appsPaths'
import appsToDevelop from '../AppsInDevelopmentMode'

module.exports = function Config({ constants }) {
  const { APP: { environment } } = constants

  if (environment === 'production') {
    return {
      LOGS_URL: 'https://logs-api.zenvia.com',
      CUSTOM_PLATFORM_URL: 'https://custom-platform.zenvia.com',
      WEB_CHAT: 'https://static.zenvia.com/embed/js/zenvia-chat.min.js',
      DEPENDENCIES: config.DEPENDENCIES.map((item) => ({ name: item.name, url: item.urlPrd })),
    }
  }

  if (environment === 'homologation') {
    return {
      ...config,
      DEPENDENCIES: config.DEPENDENCIES.map((item) => ({ name: item.name, url: item.urlHlg })),
    }
  }

  if (environment === 'development') {
    return {
      LOGS_URL: 'https://logs-api-hlg.zenvia.com',
      CUSTOM_PLATFORM_URL: 'https://custom-platform-hlg.zenvia.com',
      WEB_CHAT: 'https://static.zenvia.com/embed/js/zenvia-chat.hlg.min.js',
      DEPENDENCIES: config.DEPENDENCIES.map((item) => ({ name: item.name, url: item.urlDev })),
    }
  }

  if (environment === 'drp') {
    return {
      LOGS_URL: 'https://logs-api-drp.zenvia.com',
      CUSTOM_PLATFORM_URL: 'https://custom-platform.zenvia.com',
      WEB_CHAT: 'https://dr-static.zenvia.com/embed/js/zenvia-chat.min.js',
      DEPENDENCIES: config.DEPENDENCIES.map((item) => ({ name: item.name, url: item.urlDrp })),
    }
  }

  return {
    ...config,
    DEPENDENCIES: config.DEPENDENCIES
      .map(
        (item) => (
          {
            name: item.name,
            url: appsToDevelop[item.name] ? item.urlLocal : item.urlHlg,
          }
        ),
      ),
  }
}
