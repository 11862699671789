const paramsEncode = (url) => url.replace(/&/g, '$')

const paramsDecode = (url) => url.replace(/\$/g, '&')

const build = ({ pathName, url }) => {
  const previousRedirect = url.searchParams.get('auth0Redirect')
  if (previousRedirect) return previousRedirect // prevent successive redirects

  const route = `${pathName}${url.search}`
  const cleanedRoute = route.substring(1, route.length)
  const auth0Redirect = paramsEncode(cleanedRoute)

  return auth0Redirect
}

const decode = ({ url }) => {
  const auth0Redirect = url.searchParams.get('auth0Redirect')
  if (auth0Redirect) {
    if (auth0Redirect === 'signup') {
      return '/welcome'
    }

    const redirectParams = `/${auth0Redirect}`
    const redirectUri = decodeURIComponent(redirectParams)

    return paramsDecode(redirectUri)
  }

  return decodeURIComponent(url.search)
}

export default {
  build,
  decode,
}
