const redirectOptions = [
  {
    from: '/home/dashboard',
    to: '/welcome',
  },
  {
    from: '/home/chatbot',
    to: '/bots',
  },
  {
    from: '/flows',
    to: '/bots',
  },
  {
    from: '/signin',
    to: '/',
  },
  {
    from: '/notifications',
    to: '/attraction',
  },
  {
    from: '/campaign',
    to: '/attraction',
  },
]

// eslint-disable-next-line arrow-body-style
export default ({ navigateToUrl }) => {
  return async () => {
    const currentRoute = window.location.pathname

    const redirect = redirectOptions.find((option) => currentRoute.startsWith(option.from))

    if (redirect) {
      navigateToUrl(redirect.to)
    }
  }
}
