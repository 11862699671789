import loadDependencies from './import-dependencies'
import mapDependencies from './mapDependencies'
import mapParams from './mapParams'
import CONSTANTS from '../../Constants/Domains'

const insertNewImportMap = (newMapJSON) => {
  const map = document.querySelector('script[type="systemjs-importmap"]')
  map.text = JSON.stringify(newMapJSON)
}

const insertNewScriptTag = ({ src, type }) => {
  const element = document.createElement('script')
  element.type = type
  element.src = src

  document.querySelector('body').appendChild(element)
}

const registerDependencies = ({ environment, config }) => {
  const { DEPENDENCIES } = config
  const { apps, webComponents } = DEPENDENCIES.reduce((acc, dependency) => {
    if (['web-components', 'web-components-vue'].includes(dependency.name)) {
      acc.webComponents = [...acc.webComponents, dependency.url]
    } else {
      acc.apps = [...acc.apps, dependency]
    }

    return acc
  }, { apps: [], webComponents: [] })

  let deps = [...apps]
  let params = null
  if (environment !== 'production') {
    const { search } = window.location
    params = mapParams(search)
  }

  deps = loadDependencies({ DEPENDENCIES, params })
  deps = deps.map((dep) => {
    let context = 'APP'
    if (Object.values(CONSTANTS.SUITE).find((x) => x.name === dep.name)) context = 'SUITE'
    if (Object.values(CONSTANTS.COMMON).find((x) => x.name === dep.name)) context = 'COMMON'
    return { ...dep, context }
  })

  insertNewImportMap(mapDependencies(deps))

  webComponents.forEach((src) => {
    insertNewScriptTag({ src, type: 'module' })
  })
}

export default registerDependencies
