import Authentication from './authentication'
import FeatureToggle from './feature-toggle'
import Logger from './logger'
import EventBus from './eventbus'
import Chameleon from './chameleon'
import CustumerData from './customerData'
import FullStory from './fullStory'

export default function ({ loader, constants, config }) {
  const { DOMAINS } = constants
  const authentication = new Authentication({ loader, DOMAINS: DOMAINS.COMMON })
  const logService = Logger({ config, authentication })
  const featureToggle = new FeatureToggle({ loader, DOMAINS: DOMAINS.COMMON })
  const eventBus = new EventBus({ loader, DOMAINS: DOMAINS.COMMON })
  const chameleon = new Chameleon({ authentication })
  const fullstory = new FullStory({ authentication, constants })
  const customerData = new CustumerData({ loader, DOMAINS: DOMAINS.COMMON })

  return {
    authentication,
    featureToggle,
    fullstory,
    eventBus,
    logService,
    chameleon,
    customerData,
  }
}
