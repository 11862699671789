import { DOMAINS } from '../Constants'

export default function ({ singleSpa, loader, currentContext }) {
  const CURRENT_DOMAINS = { ...DOMAINS[currentContext], ...DOMAINS.COMMON }

  const appsToRegister = Object.keys(CURRENT_DOMAINS).filter((domain) => {
    const { isService } = CURRENT_DOMAINS[domain]
    return !isService
  })

  const pathsToHideLayout = Object.values(CURRENT_DOMAINS)
    .filter((app) => app.hideLayout && typeof app.hideLayout === 'boolean').map((app) => app.path)
  const pathsToHideSidebar = Object.values(CURRENT_DOMAINS).filter((app) => app.hideLayout === 'sidebar').map((app) => app.path)
  const pathsToHideNavbar = Object.values(CURRENT_DOMAINS).filter((app) => app.hideLayout === 'navbar').map((app) => app.path)

  const singleSpaAppsConfig = appsToRegister.map((domain) => {
    const {
      name, aliasImport, path = '', unloadApp = false, auth, hideLayout, isLayout, exact, isSidebar, isNavbar,
    } = CURRENT_DOMAINS[domain]
    const dependencies = () => loader.import(aliasImport)

    const rules = (location) => {
      if (exact && location.pathname === path) {
        return true
      }

      if (pathsToHideSidebar.find((pathToHideLayoyt) => location.pathname.startsWith(pathToHideLayoyt)) && isSidebar) {
        return false
      }

      if (pathsToHideNavbar.find((pathToHideLayoyt) => location.pathname.startsWith(pathToHideLayoyt)) && isNavbar) {
        return false
      }

      if (pathsToHideLayout.find((pathToHideLayoyt) => location.pathname.startsWith(pathToHideLayoyt)) && isLayout) {
        return false
      }

      if (!exact && (!path || location.pathname.startsWith(path))) {
        return true
      }

      if (unloadApp) {
        singleSpa.unloadApplication(name, { waitForUnmount: true })
      }
      return false
    }

    return {
      name,
      dependencies,
      rules,
      auth,
      hideLayout,
      path,
      isLayout,
    }
  })

  return {
    singleSpaAppsConfig,
  }
}
